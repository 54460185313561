/**
 *  Sagas for the voting resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { put, takeEvery, all } from "redux-saga/effects";
import { actions as ingredientsActions } from "./ingredients";
import { actions as recipesActions } from "./recipes";
import { actions as recipeStepsActions } from "./recipe_steps";
import { actions as healthyBenefitsActions } from "./healthy_benefits";
import { actions as ingredientsGroupsActions } from "./ingredients_groups";
import { actions as allergensActions } from "./allergens";
import { actions as posMediactions } from "./pos_media";
import { actions as countersActions } from "./counters";
import { actions as articlesActions } from "./articles";
import { actions as screenActions } from "./screens";
import { actions as screenChaptersActions } from "./screen_chapters";
import { actions as producersActions } from "./producers";
import { actions as clientsActions } from "./clients";
import { actions as nutrientsActions } from "./nutrients";
import { actions as locationsActions } from "./locations";
import { actions as techniquesActions } from "./techniques";
import { actions as techniquesStepsActions } from "./technique_steps";
import { actions as ingredientNutrientsActions } from "./ingredient_nutrients";
import { actions as dishesActions } from "./dishes";
import { actions as menuDishesActions } from "./menu_dishes";
import { actions as timeslotsActions } from "./timeslots";
import { actions as certificatesActions } from "./certificates";
import { actions as productsActions } from "./products";
import { actions as coursesActions } from "./courses";
import { actions as dailyMenusActions } from "./daily_menus";
import { actions as lessonChapterAnswersActions } from "./lesson_chapter_answers";
import { actions as lessonChaptersActions } from "./lesson_chapters";
import { actions as lessonsActions } from "./lessons";
import { actions as professionalUsersActions } from "./professional_users";
import { actions as recipeIngredientsActions } from "./recipe_ingredients";
import { actions as recipeStepIngredientsActions } from "./recipe_step_ingredients";
import { actions as usersActions } from "./users";
import { actions as weeklyMenusActions } from "./weekly_menus";
import { actions as feedbacksActions } from "./feedbacks";

// What to do for a new resource?
// 1.) add Import
// import {actions as resourceNameActions} from '../redux/contents';
// 2.) add store included handlinge
// } else if (entry.type === "resource_name"){
//   return put({type: resourceNameActions.addToDictionary, payload: entry});
// }

/** Worker Sagas */
export function* storeIncluded(action) {
  yield all(
    // eslint-disable-next-line array-callback-return
    action?.payload?.map((entry) => {
      if (entry.type === "ingredient") {
        return put({
          type: ingredientsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "allergen") {
        return put({
          type: allergensActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "counter") {
        return put({
          type: countersActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "recipe") {
        return put({
          type: recipesActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "pos_media") {
        return put({
          type: posMediactions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "daily_menu") {
        return put({
          type: dailyMenusActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "lesson_chapter_answer") {
        return put({
          type: lessonChapterAnswersActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "lesson_chapter") {
        return put({
          type: lessonChaptersActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "lesson") {
        return put({
          type: lessonsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "recipe_ingredient") {
        return put({
          type: recipeIngredientsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "recipe_step_ingredient") {
        return put({
          type: recipeStepIngredientsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "professional_user") {
        return put({
          type: professionalUsersActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "recipe_step") {
        return put({
          type: recipeStepsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "article") {
        return put({
          type: articlesActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "course") {
        return put({
          type: coursesActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "weekly_menu") {
        return put({
          type: weeklyMenusActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "producer") {
        return put({
          type: producersActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "users") {
        return put({
          type: usersActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "client") {
        return put({
          type: clientsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "dishes") {
        return put({
          type: dishesActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "dish") {
        return put({
          type: dishesActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "menu_dishes") {
        return put({
          type: menuDishesActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "timeslots") {
        return put({
          type: timeslotsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "ingredients_group") {
        return put({
          type: ingredientsGroupsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "nutrient") {
        return put({
          type: nutrientsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "location") {
        return put({
          type: locationsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "technique") {
        return put({
          type: techniquesActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "technique_step") {
        return put({
          type: techniquesStepsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "healthy_benefit") {
        return put({
          type: healthyBenefitsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "ingredient_nutrient") {
        return put({
          type: ingredientNutrientsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "certificate") {
        return put({
          type: certificatesActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "screen") {
        return put({
          type: screenActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "screen_chapter") {
        return put({
          type: screenChaptersActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "product") {
        return put({
          type: productsActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "feedback") {
        return put({
          type: feedbacksActions.addToDictionary,
          payload: entry,
        });
      } else if (entry.type === "example_resource") {
        // return put({type: anotherExampleResourceActions.addToDictionary, payload: entry});
      } else return null;
    })
  );
}

// // Example for Updating relationships, rarely needed
// export function* updateRelationships(action) {
//   yield all(
//     action?.payload?.map((entry) => {
//       for (const rela in entry.relationships) {
//         const relationship = entry.relationships[rela];
//         if (relationship.data?.type === 'resource_name') {
//           return put({
//             type: resourceNameActions.addRelationship,
//             payload: {
//               id: relationship.data.id,
//               type: 'related_resource_name',
//               relationship: {id: entry.id, type: entry.type},
//             },
//           });
//         }
//       }
//       return null;
//     }),
//   );
// }

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* dictionarySagas() {
  yield takeEvery("dictionary/storeIncluded", storeIncluded);
  // yield takeEvery('dictionary/updateRelationships', updateRelationships);
}
export default dictionarySagas;
