export const translations = {
  'de': {
    'LoadingContent.message': '',
    'Email': 'E-Mail',
    'Password': 'Passwort',
    'Submit': 'Speichern',
    'ForgotPassword': 'Passwort vergessen',
    'ForgotPassword.here': 'Passwort vergessen',
    'Profile': 'Profil',
    'Toppings': 'Beläge',
    'Password (confirmation)': 'Passwort Bestätigung',
    'Producer In Focus': 'PRODUZENT IM FOKUS',
    'Product in Focus': 'PRODUKT IM FOKUS',
    'Name': 'Name',
    'Healthy Benefits': 'GESUNDHEITSVORTEILE',
    'Allergens': 'Allergene',
    'No allergens in the dish.': 'Dieses Gericht enthält keine Allergene.',
    'Change password': 'Passwort ändern',
    'Insert your current password and the new password': 'Bitte geben das aktuelle Passwort und das neue Passwort',
    'Current Password': 'Aktuelles Passwort',
    'Save': 'Speichern',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home': '',
    'Home.header': '',
    'Home.header.offline': '',
    'You will receive a mail with a link to the password reset page.': 'Du erhaltest eine E-Mail mit einem Link zur Seite zum Zurücksetzen des Passworts.',
    "Healthy Fact": "GESUNDE TATSACHE",
  },
  'options': {
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'en': {
    'LoadingContent.message': '',
    'Email': 'Email',
    'Password': 'Password',
    'Submit': 'Submit',
    'ForgotPassword': 'Forgot your password? Recover',
    'ForgotPassword.here': 'here',
    'Profile': '',
    'Password (confirmation)': 'Password Confirm',
    'Producer In Focus': '',
    'Product in Focus': '',
    'Name': '',
    'Healthy Benefits': '',
    'Allergens': '',
    'No allergens in the dish.': '',
    'Change password': 'New Password',
    'Insert your current password and the new password': '',
    'Current Password': 'Password',
    'Save': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home': '',
    'Home.header': 'Welcome To Bakerplate',
    'Home.header.offline': 'Welcome To Offline Bakerplate',
    'You will receive a mail with a link to the password reset page.': '',
  },
  'it': {
    'Name': '',
    'Description': '',
    'Submit': 'Invia',
    'Items.header': 'Ciao Bakerplate',
    'Items.header.offline': 'Ciao offline Bakerplate',
    'YYYY-MM-DD': 'DD.MM.YYYY',
    'LoadingContent.message': '',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Dimenticato le credenziali? Clicca',
    'ForgotPassword.here': 'qui',
    'NewPassword': 'Nuova Password',
    'NewPasswordConfirmation': 'Conferma Nuova Password',
    'PasswordConfirmation': 'Conferma Password',
    'Number': '',
    'Text': '',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': 'Ciao Bakerplate',
    'Home.header.offline': 'Ciao offline Bakerplate',
  },
}
